<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>Detailed Sales Report</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getBasicSales()"
                :disabled="!valid"
                >Generate</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            color="btn-large btn-block primary white--text"
            class="mr-2"
            @click="downloadXLS"
            :disabled="!valid"
            >XLS</v-btn
          >
          <vue-json-to-csv
            :json-data="detailsales"
            :csv-title="`AEJ-DSR-${this.startDate}-${this.endDate}`"
          >
            <v-btn color="btn-large btn-block primary white--text"> CSV </v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="detailsales"
          item-key="invkey"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      headers: [
        {
          text: "Inv No.",
          align: "start",
          value: "invoicenum",
          width: "100px",
        },
        {
          text: "Inv Date",
          align: "start",
          value: "invoicedate",
          width: "150px",
        },

        { text: "PAX Name", align: "start", value: "paxname", width: "200px" },
        {
          text: "Employee No.",
          align: "start",
          value: "accountref",
          width: "125px",
        },
        { text: "GTS/TR No.", align: "start", value: "agencyref" },
        { text: "Project/Non-Project", align: "start", value: "project" },
        { text: "Airline Code", align: "end", value: "airline" },
        {
          text: "Ticket Number",
          align: "start",
          value: "ticketno",
          width: "200px",
        },
        { text: "PNR", align: "start", value: "pnrno", width: "250px" },
        {
          text: "Ticket Issue Date",
          align: "start",
          value: "ticketdate",
          width: "150px",
        },
        {
          text: "Departure Date",
          align: "start",
          value: "deptdate",
          width: "150px",
        },
        {
          text: "Return Date",
          align: "start",
          value: "retndate",
          width: "150px",
        },
        { text: "Flight Number", align: "start", value: "flightno" },
        { text: "Class", align: "start", value: "class" },
        { text: "Class Type", align: "start", value: "cabintype" },
        { text: "DOM/INTL", align: "start", value: "domintl" },
        { text: "OW/RT", align: "start", value: "owrt" },
        { text: "Sector", align: "start", value: "sector", width: "400px" },
        {
          text: "Origin-Destination",
          align: "start",
          value: "orgdest",
          width: "200px",
        },
        { text: "Region", align: "start", value: "region" },
        { text: "Currency", align: "start", value: "currency" },

        { text: "Base Fare", align: "end", value: "basic" },
        { text: "Taxes", align: "end", value: "tax" },
        { text: "Service Fee", align: "end", value: "service" },
        { text: "Amount in LC", align: "end", value: "total" },
        { text: "Credit No.", align: "start", value: "creditno" },
        {
          text: "Credit Date",
          align: "start",
          value: "creditdate",
          width: "150px",
        },
        { text: "Credit Amount", align: "end", value: "creditvalue" },
        { text: "Remarks", align: "start", value: "remarks" },
      ],

      detailsales: [],
      airports: [],
      carriers: [],

      ledgerKey: "",
      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
    };
  },

  mounted() {
    this.getCurrentLedgerKey();
    this.getCarriers();
    this.getAirports();
    this.getCabins();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },

    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    clearData() {
      this.detailsales = [];
    },

    getCurrentLedgerKey() {
      this.ledgerKey = localStorage.getItem("LEDG_KEY");
    },

    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("MM/DD/YYYY");
    },

    async getBasicSales() {
      if (this.validateData()) {
        this.detailsales = [];
        try {
          let response = "";
          this.loading = true;
          response = await api.post("/aejmis/api/sales", {
            startdate: this.startDate,
            enddate: this.endDate,
            ledgerkey: this.ledgerKey,
          });

          this.generateBasicSales(response.data);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    generateBasicSales(rawData) {
      this.detailsales = [];
      rawData.map(
        ({
          invoicenum,
          invoicedate,
          firstname,
          lastname,
          depdate,
          locator,
          finaldest,
          accountref,
          agencyref,
          costing,
          creditnote,
          routing,
        }) => {
          this.detailsales.push({
            invoicenum: invoicenum,
            invoicedate: this.dateFormat(invoicedate),
            paxname: `${firstname.trim()} ${lastname.trim()}`,
            accountref: accountref,
            agencyref: agencyref,
            project: "",
            airline: this.getAirline(routing),
            ticketno: this.getTicketNum(routing, costing),
            pnrno: locator,
            ticketdate: this.getTicketDate(costing),
            deptdate: this.dateFormat(depdate),
            retndate: this.getReturnDate(routing),
            flightno: this.getFlightNum(routing),
            class: this.getCabin(routing),
            cabintype: this.getCabinType(routing),
            domintl: "INTL",
            owrt: this.getOWRTN(routing),
            sector: this.encodeSectors(routing),
            orgdest: this.getORDN(routing, finaldest),
            region: "UK/EUROPE",
            currency: "GBP",
            basic: this.calcBasic(costing),
            tax: this.calcTax(costing),
            service: this.calcService(costing),
            total: this.calcInvTotal(costing),
            creditno: creditnote != null ? creditnote.creditnum : "",
            creditdate:
              creditnote != null ? this.dateFormat(creditnote.creditdate) : "",
            creditvalue:
              creditnote != null
                ? parseFloat(creditnote.creditnotevalue).toFixed(2)
                : parseFloat(0).toFixed(2),
            remarks: "",
          });
        }
      );
    },

    getAirline(routes) {
      let first = true;
      let carrier = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0 && first) {
          carrier = route.airline;
          first = false;
        }
      });
      return carrier;
    },

    getTicketNum(routes, costing) {
      let first = true;
      let prefix = "";
      let account = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0 && first) {
          account = this.carriers.filter(
            (airline) => airline.iata === route.airline
          );
          first = false;
        }
      });

      if (account.length) {
        prefix = account[0].accounting;
      } else {
        prefix = "NIL";
      }

      let ticketno = "";
      let tktFirst = true;
      costing.forEach((costItem) => {
        if (tktFirst) {
          costItem.ticket.forEach((ticketItem) => {
            ticketno = ticketItem.ticketnum;
          });
          tktFirst = false;
        }
      });
      return `${prefix}-${ticketno}`;
    },

    getTicketDate(costing) {
      let ticketdt = "";
      let tdtFirst = true;
      costing.forEach((costItem) => {
        if (tdtFirst) {
          costItem.ticket.forEach((ticketItem) => {
            ticketdt = ticketItem.issuedate;
          });
          tdtFirst = false;
        }
      });
      return this.dateFormat(ticketdt);
    },

    getReturnDate(routes) {
      let rtndt = "";
      routes.forEach((route) => {
        if (route.departuredate > "2000-01-01") {
          rtndt = moment(route.departuredate).format("MM/DD/YYYY");
        }
      });
      return rtndt;
    },

    getFlightNum(routes) {
      let flightno = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.flight.length > 0) {
          flightno = flightno = flightno + route.airline.trim() + route.flight.trim() + "-";
        }
      });
      flightno = flightno.slice(0, -1);
      return flightno;
    },

    calcBasic(costings) {
      let basic = 0;
      costings.forEach((item) => {
        let tmpDesc = item.description.toUpperCase().trim();
        let isService =
          tmpDesc.includes("TRANSACTION") || tmpDesc.includes("SERVICE");
        if (!isService) {
          basic = basic + item.quantity * (item.gross - item.tax);
        }
      });
      return parseFloat(basic).toFixed(2);
    },

    calcTax(costings) {
      let tax = 0;
      costings.forEach((item) => {
        tax = tax + item.quantity * item.tax;
      });
      return parseFloat(tax).toFixed(2);
    },

    calcService(costings) {
      let service = 0;
      costings.forEach((item) => {
        if (
          item.description.trim().toUpperCase() ===
            "TRANSACTION / SERVICE FEE" ||
          item.description.trim().toUpperCase() ===
            "TRANSACTION / SERVICE CHARGES"
        ) {
          service = service + item.quantity * item.priceeach;
        }
      });
      return parseFloat(service).toFixed(2);
    },

    calcInvTotal(costings) {
      let amount = 0;
      costings.forEach((item) => {
        amount = amount + item.quantity * item.gross;
      });
      return parseFloat(amount).toFixed(2);
    },

    getOWRTN(routes) {
      let first = true;
      let firstSector = "";
      let lastSector = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0) {
          if (first) {
            firstSector = route.from;
            lastSector = route.destination;
            first = false;
          } else {
            lastSector = route.destination;
          }
        }
      });
      return firstSector === lastSector ? "RT" : "OW";
    },

    getORDN(routes, finaldest) {
      let first = true;
      let firstsector = "";
      let lastsector = finaldest;
      let found = false;

      this.airports.forEach((airport) => {
        if (!found) {
          if (airport.cityname == finaldest) {
            lastsector = airport.citycode;
            console.log(lastsector);
            found = true;
          }
        }
      });
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0) {
          if (route.source === null || route.target === null) {
            if (first) {
              firstsector = route.from;
              first = false;
            }
          } else if (route.source != null && route.target != null) {
            if (first) {
              firstsector = route.source.citycode;
              first = false;
            }
          }
        }
      });
      return `${firstsector}-${lastsector}`;
    },

    encodeSectors(routes) {
      let first = true;
      let sector = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0) {
          if (route.source === null || route.target === null) {
            if (first) {
              sector = route.from + "-" + route.destination;
              first = false;
            } else {
              sector = sector + "-" + route.destination;
            }
          } else if (route.source != null && route.target != null) {
            if (first) {
              sector = route.source.citycode + "-" + route.target.citycode;
              first = false;
            } else {
              sector = sector + "-" + route.target.citycode;
            }
          }
        }
      });
      return sector;
    },

    // Sort routing key
    customCompare(a, b) {
      return a.routingkey - b.routingkey;
    },

    getCabin(routes) {
      let first = true;
      let cabin = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.class.length > 0 && first) {
          cabin = route.class;
          first = false;
        }
      });
      return cabin;
    },

    getCabinType(routes) {
      let firstCarrier = true;
      let carrier = "";
      let sortedRoutes = routes.sort(this.customCompare);
      sortedRoutes.forEach((route) => {
        if (route.airline.length > 0 && firstCarrier) {
          carrier = route.airline;
          firstCarrier = false;
        }
      });

      const cabins = routes.filter((route) => route.airline === carrier);
      let firstCabin = true;
      let cabinClass = "";
      cabins.forEach((cabin) => {
        if (cabin.class.length > 0 && firstCabin) {
          cabinClass = cabin.class;
          firstCabin = false;
        }
      });

      const cabinDecoded = this.cabins.filter(
        (cabin) => cabin.cabincode === cabinClass.toUpperCase()
      );
      if (cabinDecoded.length > 0) {
        return cabinDecoded[0].cabinname;
      } else {
        return "";
      }
    },

    async getCabins() {
      try {
        this.cabins = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/cabins");
        this.cabins = response.data;
        console.log(this.cabins);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve cabin information. Try after sometime.",
        });
      }
    },

    async getCarriers() {
      try {
        this.airlines = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/airlines");
        this.carriers = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not airlines information. Try after sometime.",
        });
      }
    },

    async getAirports() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/airports");
        const data = response.data;
        data.map((airport) => {
          if (airport.cityname != null) {
            this.airports.push({
              citycode: airport.citycode.trim(),
              cityname: airport.cityname.trim(),
            });
          }
        });
        console.log(this.airports);
        this.loading = false;
        return;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve information. Try after sometime.",
        });
      }
    },

    async downloadXLS() {
      const ExcelJS = require("exceljs");
      const fileSaver = require("file-saver");

      // Create workbook & add worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Airline Ranking");

      // add column headers
      worksheet.columns = [
        { header: "Invoice No.", key: "invoicenum", width: 10 },
        { header: "Invoice Date", key: "invoicedate", width: 15 },
        { header: "Pax Name", key: "paxname", width: 50 },
        { header: "Employee No.", key: "accountref", width: 20 },
        { header: "GTS/TR No.", key: "agencyref", width: 20 },
        { header: "Project/Non-Project", key: "project", width: 20 },
        { header: "Airline", key: "airline", width: 10 },
        { header: "Ticket No.", key: "ticketno", width: 20 },
        { header: "PNR", key: "pnrno", width: 15 },
        { header: "Ticket Issue Date", key: "ticketdate", width: 20 },
        { header: "Departure Date", key: "deptdate", width: 15 },
        { header: "Return Date", key: "retndate", width: 15 },
        { header: "Flight No.", key: "flightno", width: 15 },
        { header: "Class", key: "class", width: 10 },
        { header: "Class Type", key: "cabintype", width: 20 },
        { header: "DOM/INTL", key: "domintl", width: 10 },
        { header: "OW/RT", key: "owrt", width: 10 },
        { header: "Sector", key: "sector", width: 50 },
        { header: "Origin-Destination", key: "orgdest", width: 35 },
        { header: "Region", key: "region", width: 20 },
        { header: "Currency", key: "currency", width: 10 },
        { header: "Base Fare", key: "basic", width: 15 },
        { header: "Tax", key: "tax", width: 15 },
        { header: "Service Fee", key: "service", width: 15 },
        { header: "Amount in LC", key: "total", width: 15 },
        { header: "Credit No.", key: "creditno", width: 10 },
        { header: "Credit Date", key: "creditdate", width: 15 },
        { header: "Credit Amount", key: "creditvalue", width: 15 },
        { header: "Remarks", key: "remarks", width: 25 },
      ];

      // make header bold
      worksheet.getRow(1).font = { name: "Roboto", bold: true };

      // style columns
      worksheet.columns[0].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[1].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[2].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[3].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[4].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[5].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[6].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[7].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[8].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[9].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[10].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[11].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[12].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[13].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[14].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[15].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[16].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[17].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[18].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[19].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[20].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[21].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[22].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[23].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[24].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[25].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[26].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[27].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[28].alignment = {
        vertical: "middle",
        horizontal: "left",
      };

      // Add rows using both the above of rows
      if (this.detailsales.length > 0) {
        let rows = [];
        this.detailsales.forEach((sale) => {
          rows.push({
            invoicenum: sale.invoicenum,
            invoicedate: sale.invoicedate,
            paxname: sale.paxname,
            accountref: sale.accountref,
            agencyref: sale.agencyref,
            project: sale.project,
            airline: sale.airline,
            ticketno: sale.ticketno,
            pnrno: sale.pnrno,
            ticketdate: sale.ticketdate,
            deptdate: sale.deptdate,
            retndate: sale.retndate,
            flightno: sale.flightno,
            class: sale.class,
            cabintype: sale.cabintype.trim(),
            domintl: sale.domintl,
            owrt: sale.owrt,
            sector: sale.sector,
            orgdest: sale.orgdest,
            region: sale.region,
            currency: sale.currency,
            basic: sale.basic,
            tax: sale.tax,
            service: sale.service,
            total: sale.total,
            creditno: sale.creditno,
            creditdate: sale.creditdate,
            creditvalue: sale.creditvalue,
            remarks: sale.remarks,
          });
        });

        worksheet.addRows(rows);

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], { type: "application/octet-stream" });
          fileSaver.saveAs(
            blob,
            `AEJ-DSR-${this.startDate}-${this.endDate}.xlsx`
          );
        });
      }
    },
  },
};
</script>
